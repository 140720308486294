import React from 'react'
import { Home } from 'views/home'
import { NoMatch } from 'views/no-match'
import { Route, Switch } from 'react-router-dom'

export function App() {
    return (
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route component={NoMatch}/>
        </Switch>
    )
}
