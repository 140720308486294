import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export function NoMatch() {
    return (
        <>

            <Helmet>
                <title>404 | stonks</title>
            </Helmet>

            <div className='h-screen'>
                <div className='container mx-auto h-full flex flex-col items-center justify-center text-center'>
                    <div className='flex flex-col md:flex-row items-center justify-center'>
                        <div className='text-4xl md:text-2xl align-middle font-medium'>
                            404
                        </div>
                        <div className='text-xl text-indigo-400 align-middle mx-4 hidden md:inline-block'>
                            |
                        </div>
                        <div className='text-xl align-middle'>
                            This page could not be found 🙄
                        </div>
                    </div>
                    <div className='mt-4'>
                        <Link to='/' className='font-medium text-gray-400 hover:text-gray-200 focus:text-gray-200 transition duration-150 ease-in-out'>
                            ⟶ back to home
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}
