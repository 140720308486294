import React from 'react'

export function CoinPercent({ value }) {

    function color(value) {
        if (Math.abs(value) < 1) return 'gray'
        if (value < -2) return 'red'
        if (value < 0) return 'orange'
        return 'green'
    }

    return (
        <div className={`leading-5 text-${color(value)}-400`}>
            {value} %
        </div>
    )
}
