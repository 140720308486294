import React from 'react'
import { CoinHigh } from 'views/home/coin-high'
import { CoinIndex } from 'views/home/coin-index'
import { CoinPercent } from 'views/home/coin-percent'

function round(value, d = 2) {
    return (Math.round(value * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)
}

function price(value) {
    if (value < 1) {
        return round(value, 4)
    } else if (value < 10) {
        return round(value, 3)
    } else if (value < 100) {
        return round(value, 2)
    } else {
        return round(value, 1)
    }
}

export function CoinItem({ coin }) {
    return (
        <tr>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <div className='flex items-center'>
                    <div className='flex-shrink-0 h-8 w-8'>
                        <a href={`https://alternative.me/crypto/coins/${coin.id}/`} target='_blank' rel='noreferrer noopener'>
                            <img className='h-8 w-8' src={coin.image} alt='coin'/>
                        </a>
                    </div>
                    <div className='ml-4'>
                        <div className='leading-5 font-medium text-white'>
                            {coin.name} ({coin.symbol.toUpperCase()})
                        </div>
                        <div className='leading-5 text-sm text-gray-400'>
                            Rank {coin.market_cap_rank}
                        </div>
                    </div>
                </div>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <div className='leading-5 text-gray-400'>
                    ${price(coin.current_price)}
                </div>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <CoinHigh value={round(coin.ath_change_percentage)} date={coin.ath_date}/>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <CoinPercent value={round(coin.price_change_percentage_24h_in_currency)}/>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <CoinPercent value={round(coin.price_change_percentage_30d_in_currency)}/>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <CoinPercent value={round(coin.price_change_percentage_200d_in_currency)}/>
            </td>
            <td className='px-6 py-4 whitespace-no-wrap'>
                <CoinIndex value={Math.trunc(coin.index)}/>
            </td>
        </tr>
    )
}
