module.exports = [
    {
        key: 'ath_change_percentage',
        weight: 3,
        scale: [
            { value: -80, trust: 100 },
            { value: -40, trust: 0 }
        ]
    },
    {
        key: 'price_change_percentage_1h_in_currency',
        weight: 3,
        scale: [
            { value: 2, trust: 100 },
            { value: 10, trust: 0 }
        ]
    },
    {
        key: 'price_change_percentage_24h_in_currency',
        weight: 2,
        scale: [
            { value: 5, trust: 100 },
            { value: 10, trust: 0 }
        ]
    },
    {
        key: 'price_change_percentage_30d_in_currency',
        weight: 2,
        scale: [
            { value: 5, trust: 100 },
            { value: 15, trust: 0 }
        ]
    },
    {
        key: 'price_change_percentage_200d_in_currency',
        weight: 1,
        scale: [
            { value: 10, trust: 100 },
            { value: 20, trust: 0 }
        ]
    },
    {
        key: 'price_change_percentage_1y_in_currency',
        weight: 1,
        scale: [
            { value: 15, trust: 100 },
            { value: 25, trust: 0 }
        ]
    }
]
