import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/use-stores'

export const Filters = observer(() => {
    const { geckoStore } = useStores()

    function onRankChange(e) {
        geckoStore.setRank(e.target.value)
    }

    return (
        <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                <div className='px-4 py-4 bg-gray-800 rounded-lg shadow-lg'>
                    <label className='flex items-center space-x-3'>
                        <input type='number' name='rank' min={10} max={200} defaultValue={geckoStore.rank} onChange={onRankChange} className='text-gray-700 px-2 py-1 rounded-md'/>
                        <span className='text-white font-medium'>
                            Minimal rank
                        </span>
                    </label>
                </div>
            </div>
        </div>
    )
})
