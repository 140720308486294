import axios from 'axios'
import config from 'stores/config'
import { makeAutoObservable, reaction } from 'mobx'

const gecko = axios.create({ baseURL: process.env.REACT_APP_GECKO_URL })

export class GeckoStore {
    data = []
    rank = 50

    constructor() {
        makeAutoObservable(this)

        this.rank = Number(localStorage.getItem('gecko-rank')) || 50

        reaction(() => this.rank, rank => localStorage.setItem('gecko-rank', String(rank)))
    }

    async load() {
        const { data: coins } = await gecko.get('coins/markets', {
            params: {
                per_page: 200,
                vs_currency: 'usd',
                price_change_percentage: '1h,24h,7d,14d,30d,200d,1y'
            }
        })

        this.data = coins
            .map(coin => this.getIndex(coin))
            .sort((a, b) => b.index - a.index)
    }

    setRank(rank) {
        this.rank = Math.max(rank, 10)
    }

    getIndex(coin) {
        const weight = config.map(c => c.weight).reduce((acc, e) => acc + e, 0)
        const values = config.map(c => this.scale(coin[c.key], c.scale) * c.weight / weight)

        coin.index = values.reduce((acc, e) => acc + e, 0)

        return coin
    }

    scale(value, scale) {
        const head = scale[0]
        const last = scale[scale.length - 1]

        if (value === null) {
            return 0
        }

        if (value < head.value) {
            return head.trust
        }

        function apply(value, oMin, oMax, nMin, nMax) {
            return (nMax - nMin) / (oMax - oMin) * (value - oMax) + nMax
        }

        for (let i = 1; i < scale.length; i++) {
            const curr = scale[i]
            const prev = scale[i - 1]

            if (value < curr.value) {
                return apply(value, prev.value, curr.value, prev.trust, curr.trust)
            }
        }

        return last.trust
    }

    get coins() {
        if (!this.data.length) return []

        const coins = this.data.filter(coin => !this.stable.includes(coin.symbol.toUpperCase()))

        if (this.rank) {
            return coins.filter(coin => coin.market_cap_rank <= this.rank)
        }

        return coins
    }

    get stable() {
        return [
            'BUSD',
            'CDAI',
            'CUSDC',
            'CUSDT',
            'DAI',
            'HUSD',
            'PAX',
            'SUSD',
            'TUSD',
            'USDC',
            'USDN',
            'USDT',
            'UST'
        ]
    }
}
