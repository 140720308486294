import React from 'react'

export function CoinIndex({ value }) {

    function color(value) {
        if (value < 80) return 'red'
        if (value < 90) return 'orange'
        return 'green'
    }

    return (
        <div className={`leading-5 text-${color(value)}-400`}>
            {value} %
        </div>
    )
}
