import axios from 'axios'
import { makeAutoObservable } from 'mobx'

const http = axios.create({ baseURL: process.env.REACT_APP_INDEX_URL })

export class IndexStore {
    data = []

    constructor() {
        makeAutoObservable(this)
    }

    async load() {
        const params = {
            limit: 5
        }

        const { data: { data } } = await http.get('fng/', { params })

        this.data = data
    }

    get index() {
        if (!this.data.length) return '?'

        return this.data[0].value
    }
}
