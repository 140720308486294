import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/use-stores'

export const Title = observer(() => {
    const { indexStore } = useStores()

    React.useEffect(() => indexStore.load(), [indexStore])

    return (
        <div className='px-4 lg:flex lg:items-center lg:justify-between'>
            <h1 className='text-3xl font-bold leading-7 text-white'>
                Coins Ranking
            </h1>
            <div className='flex items-center mt-4 lg:mt-0'>
                <p className='text-gray-200'>
                    Fear & Greed Index
                </p>
                <p className='text-2xl font-bold text-indigo-400 align-middle px-2'>
                    <a href='https://alternative.me/crypto/fear-and-greed-index' target='_blank' rel='noreferrer noopener'>
                        {indexStore.index}
                    </a>
                </p>
            </div>
        </div>
    )
})
