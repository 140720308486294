import React from 'react'
import moment from 'moment'

export function CoinHigh({ value, date }) {
    const days = moment().diff(moment(date), 'days')

    function color(value) {
        if (value < -80) return 'green'
        if (value < -40) return 'orange'
        return 'red'
    }

    return (
        <>
            <div className={`leading-5 text-${color(value)}-400`}>
                {value} %
            </div>
            <div className='leading-5 text-sm text-gray-400'>
                {days} days ago
            </div>
        </>
    )
}
