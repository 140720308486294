import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/use-stores'
import { CoinItem } from 'views/home/coin-item'

function Title({ name }) {
    return (
        <th className='px-6 py-3 bg-gray-900 text-left text-xs leading-5 font-medium text-gray-200 uppercase tracking-wider'>
            {name}
        </th>
    )
}

export const CoinView = observer(() => {
    const { geckoStore } = useStores()

    React.useEffect(() => {
        const interval = setInterval(() => geckoStore.load(), 30000)

        return () => clearInterval(interval)
    }, [geckoStore])

    React.useEffect(() => geckoStore.load(), [geckoStore])

    return (
        <div className='flex flex-col'>
            <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    <div className='shadow-lg overflow-hidden sm:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-900'>
                            <thead>
                            <tr>
                                <Title name='Coin'/>
                                <Title name='Price'/>
                                <Title name='ATH Change'/>
                                <Title name='24h Change'/>
                                <Title name='30d Change'/>
                                <Title name='200d Change'/>
                                <Title name='Buy Index'/>
                            </tr>
                            </thead>
                            <tbody className='bg-gray-800 divide-y divide-gray-900'>
                            {geckoStore.coins.length ? (
                                <>
                                    {geckoStore.coins.map(coin => (
                                        <CoinItem coin={coin} key={coin.id}/>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan='7'>
                                        <div className='min-w-full text-center pb-8 pt-12'>
                                            <svg className='inline-block w-12 h-12 text-gray-200 animate-bounce' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z'/>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
})
