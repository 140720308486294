import React from 'react'
import { Helmet } from 'react-helmet'
import { Title } from 'views/home/title'
import { Filters } from 'views/home/filters'
import { CoinView } from 'views/home/coin-view'

export function Home() {
    return (
        <>

            <Helmet>
                <title>stonks</title>
            </Helmet>

            <div className='container mx-auto mt-16 mb-8'>
                <Title/>
            </div>

            <div className='container mx-auto my-8'>
                <Filters/>
            </div>

            <div className='container mx-auto my-8'>
                <CoinView/>
            </div>

        </>
    )
}
